import { useEffect, useState } from 'react'

import type { WindowWithOneTrust } from '@cms/components/OneTrustCookieManager'

type OneTrustHook = {
  isTargetingCookieSet: boolean
}

const TARGETING_CATEGORY = 'C0004'

export const useOneTrust = (): OneTrustHook => {
  const windowWithOneTrust: WindowWithOneTrust = typeof window !== 'undefined' && window
  const [isTargetingCookieSet, setIsTargetingCookieSet] = useState(false)

  const handleOneTrustEvent = (oneTrustActiveGroupEvent) => {
    setIsTargetingCookieSet(oneTrustActiveGroupEvent?.detail?.includes(TARGETING_CATEGORY))
  }

  useEffect(() => {
    setIsTargetingCookieSet(
      windowWithOneTrust?.OnetrustActiveGroups?.includes(TARGETING_CATEGORY),
    )

    window.addEventListener('OneTrustGroupsUpdated', handleOneTrustEvent)

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handleOneTrustEvent)
    }
  }, [windowWithOneTrust?.OnetrustActiveGroups])

  return {
    isTargetingCookieSet,
  }
}

export default useOneTrust
